<template>
  <div class="chats" id="mychat">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      class="chat"
      :class="{ 'chat-left': msgGrp.senderId === false }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="primary"
          :src="msgGrp.senderId === false && formattedChatData.contact.avatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msgData, ind) in msgGrp.messages"
          :key="ind"
          class="chat-content"
        >
          <p class="">{{ msgData.msg }}</p>
          <!-- <p>{{ msgData.media }}</p> -->
          <div>
            <b-img
              v-if="msgData.media && isImage(msgData.media)"
              :src="msgData.media"
              height="200px"
              width="200px"
              class="mt-1"
              style="object-fit: contain; background-color: white"
            />
            <video
              v-else-if="isVideo(msgData.media)"
              controls
              controlslist="nodownload"
              class="mt-50"
              style="height: 200px; width: 200px"
            >
              <source :src="msgData.media" type="video/mp4" />
            </video>
            <audio controls v-if="msgData.audio">
              <source :src="msgData.audio" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>

          <small
            :class="[msgGrp.senderId === false ? 'float-left' : 'float-right']"
          >
            {{
              formatDateToMonthShort(msgData.time, {
                hour: "numeric",
                minute: "numeric",
              })
            }}
          </small>
        </div>
      </div>
      <!-- <div :class="[msgGrp.senderId === false ? 'text-left' : 'text-right']">
        <p>
          {{ msgGrp.messages[msgGrp.messages.length - 1].time.split("T")[1] }}
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import { formatDateToMonthShort } from "@core/utils/filter";
import { BAvatar, BImg } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
    BImg,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
    userChat: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      // console.log(props.userChat);
      // console.log(props.chatData);

      const contact = {
        id: props.chatData.localID,
        avatar: props.chatData.avatar,
      };

      let chatLog = props.userChat;
      // if (props.chatData.chat) {
      //   chatLog = props.chatData.chat.chat;
      // }
      const formattedChatLog = [];
      // let chatMessageSenderId = props.userChat[0] ? props.userChat[0].senderID : undefined;

      let chatMessageSenderId = chatLog[0] ? chatLog[0].fromAdmin : undefined;
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.fromAdmin) {
          msgGroup.messages.push({
            msg: msg.message,
            media: msg.media,
            audio: msg.audio,
            time: msg.date,
          });
        } else {
          chatMessageSenderId = msg.fromAdmin;
          formattedChatLog.push(msgGroup);
          msgGroup = {
            senderId: msg.fromAdmin,
            messages: [
              {
                msg: msg.message,
                media: msg.media,
                audio: msg.audio,
                time: msg.date,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
      });

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      };
    });

    return {
      formattedChatData,
      formatDateToMonthShort,
    };
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
  },
};
</script>

<style lang="css" scoped>
.chat-app-window .chats .chat-body .chat-content {
  background: none;
  background-color: white;
  color: #6e6b7b;
}
</style>
