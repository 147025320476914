<template>
  <component :is="tag" v-on="$listeners" class="d-flex">
    <!-- <b-avatar
      v-if="user.avatar == null || user.avatar == ''"
      size="42"
      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
      class="badge-minimal"
      variant="transparent"
    />
    <b-avatar
      v-else
      size="42"
      :src="user.avatar"
      class="badge-minimal"
      variant="transparent"
    /> -->
    <!-- :badge="isChatContact" -->
    <!-- :badge-variant="resolveAvatarBadgeVariant(user.status)" -->
    <div class="chat-info flex-grow-1 mt-0">
      <h5 class="mb-0">
        {{ user.name ? user.name : "--" }}
      </h5>
      <p class="card-text text-truncate mb-0" v-if="isMember">
        {{ `Reg: ${user.regNum ? user.regNum : "--"}` }}
      </p>
      <p class="card-text text-truncate mb-0" v-if="isMember">
        Due:
        {{
          new Date(user.dueDate).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })
        }}
      </p>
      <p class="card-text text-truncate mb-0" v-if="isMember">
        Exp:
        {{
          new Date(user.expiryDate).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })
        }}
      </p>
      <!-- <b-badge variant="light-primary" v-if="isMember">
        {{
          new Date(user.dueDate).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })
        }}
      </b-badge> -->

      <!-- {{ isChatContact ? user.message : user.message }} -->
    </div>
    <div v-if="isChatContact" class="chat-meta text-nowrap">
      <feather-icon
        v-if="rights.edit"
        class="cursor-pointer text-primary"
        icon="MessageCircleIcon"
        size="16"
        v-b-tooltip.hover.top
        title="Send Message"
      />
      <!-- <feather-icon
        v-if="rights.edit"
        class="cursor-pointer text-primary"
        icon="Edit3Icon"
        size="16"
        v-b-tooltip.hover.top
        title="Edit"
        @click.stop="$emit('edit-group', user)"
      />
      <feather-icon
        v-if="rights.delete"
        class="ml-50 cursor-pointer text-danger"
        icon="Trash2Icon"
        size="16"
        v-b-tooltip.hover.top
        title="Delete"
        @click.stop="$emit('delete-group', user.id)"
      /> -->
    </div>
    <div v-else-if="isMember">
      <span class="font-weight-bold">Total: {{ user.total }}</span>
      <!-- <feather-icon
        v-if="rights.edit"
        class="cursor-pointer text-primary"
        icon="Edit3Icon"
        size="16"
        v-b-tooltip.hover.top
        title="Edit"
        @click.stop="$emit('edit-member', user)"
      />
      <feather-icon
        v-if="rights.delete"
        class="ml-50 cursor-pointer text-danger"
        icon="Trash2Icon"
        size="16"
        v-b-tooltip.hover.top
        title="Delete"
        @click.stop="$emit('delete-member', user.id)"
      /> -->
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge, VBTooltip } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import useChat from "./useChat";

export default {
  components: {
    BAvatar,
    BBadge,
    VBTooltip,
  },
  props: {
    rights: {
      type: Object,
      required: true,
    },
    tag: {
      type: String,
      default: "div",
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
    isMember: {
      type: Boolean,
      dedfault: false,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
  methods: {
    testing(btn) {
      console.log(btn);
      // later on will use emit to open sidebar
    },
  },
};
</script>

<style></style>
